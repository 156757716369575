<template>
  <div class="l">
    <main class="l-main">
      <header class="l-header">
        <h1 class="l-heading">{{content.heading}}</h1>
        <h2 class="l-subheading">{{content.subheading}}</h2>
        <button class="ui-button" type="button" name="button" @click="navigate(`https://tom702312.typeform.com/to/GHZ4p8t2`)">
          Get Early Access
        </button>
      </header>
      <section class="l-content">
        <img class="l-content__product" src="../assets/ff-product.svg" alt="">
        <section class="l-subcontent">
          <div class="l-subcontent__block">
            <h3>{{content.block_heading}}</h3>
            <p v-for="(block, index) in content.block_content" :key="index">{{block}}</p>
          </div>
          <div class="l-subcontent__block">
            <h3>Looking for someone doing it successfully? Check out this channel.</h3>
            <a class="l__channel-link" target="_blank" href="https://babybychickpeas.fanfolk.com" v-if="content.example">
              <div class="link-content is-image">
                <img class="" src="../assets/gracie.jpg" alt="">
              </div>
              <div class="link-content is-main">
                <h3>Baby by Chickpeas</h3>
                <p>Exclusive content and materials from Gracie Pascale, a registered dietitian nutritionist.</p>
              </div>
            </a>
          </div>
        </section>
        <section class="l-content is-cta">
          <h2>We just opened up a new, limited batch of invites for qualified creators.</h2>
          <button class="ui-button" type="button" name="button" @click="navigate(`https://tom702312.typeform.com/to/GHZ4p8t2`)">
            Apply for Access Today
          </button>
        </section>
      </section>
    </main>
  </div>
</template>
<script>
export default {
  name: 'Home',
  props: [
    'navigate',
    'content',
  ],
  data() {
    return {
    }
  },
  methods: {
  },
  created() {
    const id = this.$route.params.name ? this.$route.params.name : 'No ID';
    analytics.track('Page Viewed', { name: 'Landing', page: id });
  },
};
</script>
<style scoped lang="scss">
  @import "../assets/styles/app.scss";
  @import "../assets/styles/landing.scss";
</style>
